import React, { lazy, Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { getAuth0Token } from "../../redux/authorization/actions";
import { Helmet } from "react-helmet";
import useGaTracker from "../../utils/useGaTracker";

import ProtectedRoute from "../../components/routes/ProtectedRoute";
import LoadingWidget from "../../components/app/Loading/LoadingWidget";

// import Notifier from "../../components/_archived/acsselfservice/v2/Notifier";

const LandingPage = lazy(() =>
  import(/* webpackChunkName: "LandingPage" */ "../LandingPage/LandingPage")
);

const Management = lazy(() =>
  import(
    /* webpackChunkName: "Management" */ "../OSVisualization/Management/Management"
  )
);

const DistributedView = lazy(() =>
  import(
    /* webpackChunkName: "DistributedView" */ "../OSVisualization/DistributedView"
  )
);

const EngineeringView = lazy(() =>
  import(
    /* webpackChunkName: "EngineeringView" */ "../OSVisualization/Engineering/Engineering"
  )
);

const SecurityView = lazy(() =>
  import(
    /* webpackChunkName: "SecurityView" */ "../OSVisualization/SecurityView/SecurityView"
  )
);

// const ScheduleView = lazy(() =>
//   import(
//     /* webpackChunkName: "ScheduleView" */ "../OSVisualization/ScheduleView/ScheduleView"
//   )
// );

const ScheduleView = lazy(() =>
  import(
    /* webpackChunkName: "Schedule" */ "../OSVisualization/Schedule/Schedule"
  )
);

const SelfServiceView = lazy(() =>
  import(
    /* webpackChunkName: "SelfServiceView" */ "../SelfServiceView/SelfServiceView"
  )
);

const CSMView = lazy(() =>
  import(/* webpackChunkName: "csmView" */ "../csmView")
);

const SituationRoomLandingPage = lazy(() =>
  import(
    /* webpackChunkName: "cshLandingPage" */ "../SituationRoom/SituationRoomLandingPage"
  )
);

const SituationRoom = lazy(() =>
  import(/* webpackChunkName: "CSH" */ "../SituationRoom/SituationRoom")
);

const SituationRoomDatafication = lazy(() =>
  import(
    /* webpackChunkName: "cshDatafication" */ "../SituationRoom/Datafication"
  )
);

const SituationRoomDataficationReport = lazy(() =>
  import(
    /* webpackChunkName: "cshDataficationReport" */ "../SituationRoom/DataficationReport"
  )
);

const SituationRoomInsights = lazy(() =>
  import(
    /* webpackChunkName: "SituationRoomInsights" */ "../SituationRoom/Insights"
  )
);

const SituationRoomITSDLandingPage = lazy(() =>
  import(
    /* webpackChunkName: "SituationRoomITSDLandingPage" */ "../SituationRoomITSD/ITSDLandingPage"
  )
);

const SituationRoomITSD = lazy(() =>
  import(
    /* webpackChunkName: "SituationRoomITSD" */ "../SituationRoomITSD/ITSDRoom"
  )
);

const SituationRoomITSDUserManagement = lazy(() =>
  import(
    /* webpackChunkName: "SituationRoomITSDUserManagement" */ "../SituationRoomITSD/ITSDUserManagement"
  )
);

const NetworkView = lazy(() =>
  import(/* webpackChunkName: "networkView" */ "../networkView")
);

const OCCDashboad = lazy(() =>
  import(/* webpackChunkName: "OCCDashboard" */ "../OCCDashboard/OCCDashboard")
);

const GitVisualization = lazy(() =>
  import(
    /* webpackChunkName: "GitVisualization" */ "../GitVisualization/GitVisualization"
  )
);

const DBManagementView = lazy(() =>
  import(
    /* webpackChunkName: "DBManagementView" */ "../DBVisualization/Management"
  )
);

const ServiceMappingView = lazy(() =>
  import(
    /* webpackChunkName: "ServiceMapping" */ "../OSVisualization/ServiceMapping/ServiceMapping"
  )
);

const ServiceMappingRequestView = lazy(() =>
  import(
    /* webpackChunkName: "ServiceMappingRequestView" */ "../OSVisualization/ServiceMapping/ServiceMappingRequestView"
  )
);

const ServiceMappingHistoryView = lazy(() =>
  import(
    /* webpackChunkName: "ServiceMappingHistoryView" */ "../OSVisualization/ServiceMapping/HistoryView"
  )
);

const VMVulnerablities = lazy(() =>
  import(
    /* webpackChunkName: "SecurityView" */ "../OSVisualization/VMInsights/VMInsights"
  )
);

const GAView = lazy(() =>
  import(/* webpackChunkName: "GAView" */ "../GA/index")
);

const MLOpsDashboard = lazy(() =>
  import(/* webpackChunkName: "MLOpsDashboard" */ "../MLOps")
);

const NewMLOpsDashboard = lazy(() => import("../NewMLOPs"));

const StorageVisualization = lazy(() =>
  import(
    /* webpackChunkName: "StorageVisualization" */ "../StorageVisualization"
  )
);

const NotFound = lazy(() =>
  import(/* webpackChunkName: "NotFound" */ "../NotFound/NotFound")
);

const Middleware = lazy(() =>
  import(/* webpackChunkName: "Middleware" */ "../Middleware/Middleware")
);

const ShiftHandover = lazy(() =>
  import(/* webpackChunkName: "ShiftHandover" */ "../ShiftHandover/index")
);

const ShiftHandoverDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "ShiftHandoverView" */ "../ShiftHandover/DetailsPage/index"
  )
);
const ShiftHandoverUserManagement = lazy(() =>
  import(
    /* webpackChunkName: "ShiftHandoverView" */ "../ShiftHandover/UserManagement"
  )
);
const ShiftHandoverBanner = lazy(() =>
  import(
    /* webpackChunkName: "ShiftHandoverView" */ "../ShiftHandover/Banner"
  )
);

const ShiftHandoverLiveGlobalDashboard = lazy(() =>
  import(
    /* webpackChunkName: "ShiftHandoverView" */ "../ShiftHandover/LiveGlobalDashboard"
  )
);

const ShiftHandoverLiveIndividualDashboard = lazy(() =>
  import(
    /* webpackChunkName: "ShiftHandoverView" */ "../ShiftHandover/LiveIndividualDashboard"
  )
);

// const Managemenetview = lazy(() =>
//   import(
//     /* webpackChunkName: "ManagementView" */ "../_archived/ManagementView/ManagementView"
//   )
// );

// const ACNLifeCycleManagement = lazy(() =>
//   import(
//     /* webpackChunkName: "ACNLifeCycleManagement" */ "../_archived/acsselfonboarding/ACNLifeCycleManagement"
//   )
// );

// const ChangeAutomation = lazy(() =>
//   import(
//     /* webpackChunkName: "ChangeAutomation" */ "../_archived/ChangeAutomation/ChangeAutomation"
//   )
// );

const App = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  useGaTracker();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const setToken = async () => {
        try {
          const token = await getAccessTokenSilently();
          dispatch(getAuth0Token(token));
        } catch (error) {
          console.error(`Error while getting the token, ${error}`);
        }
      };
      setToken();
      dispatch({ type: "SET_USER_EMAIL", payload: user?.email });
      dispatch({
        type: "SET_USER_GROUPS",
        payload: user?.["https://accounts.ikea.com/groups"],
      });
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch, user]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });

  return (
    <>
      <Helmet>
        <title>iObserve</title>
        <meta name='description' content='iObserve' />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<LoadingWidget />}>
          <Switch>
            <ProtectedRoute exact path='/' component={LandingPage} />
            <ProtectedRoute
              exact
              path='/osvisualization'
              component={Management}
            />
            <ProtectedRoute
              exact
              path='/osvisualization/management-view'
              component={Management}
            />
            <ProtectedRoute
              path='/osvisualization/engineering'
              component={EngineeringView}
            />
            <ProtectedRoute
              path='/osvisualization/distributed'
              component={DistributedView}
            />
            <ProtectedRoute path='/self-service' component={SelfServiceView} />
            <ProtectedRoute
              path='/osvisualization/schedule'
              component={ScheduleView}
            />
            <ProtectedRoute
              path='/osvisualization/security-view'
              component={SecurityView}
            />
            {process.env.REACT_APP_ENV === "DEV" && (
              <ProtectedRoute path='/apps/management/csm' component={CSMView} />
            )}
            <ProtectedRoute path='/OCC-dashboard' component={OCCDashboad} />
            <ProtectedRoute
              path='/git-visualization'
              component={GitVisualization}
            />
            <ProtectedRoute
              path='/dbvisualization'
              component={DBManagementView}
            />
            <ProtectedRoute
              exact
              path='/osvisualization/servicemapping'
              component={ServiceMappingView}
            />
            <ProtectedRoute
              exact
              path='/osvisualization/servicemapping/requests'
              component={ServiceMappingRequestView}
            />
            <ProtectedRoute
              exact
              path='/osvisualization/servicemapping/history'
              component={ServiceMappingHistoryView}
            />
            {process.env.REACT_APP_ENV === "DEV" && (
              <ProtectedRoute path='/GA' component={GAView} />
            )}
            {process.env.REACT_APP_ENV === "DEV" && (
              <ProtectedRoute path='/mlops' component={MLOpsDashboard} />
            )}
            {process.env.REACT_APP_ENV === "DEV" && (
              <ProtectedRoute path='/new-mlops' component={NewMLOpsDashboard} />
            )}
            <ProtectedRoute
              path='/storage-visualization'
              component={StorageVisualization}
            />
            <ProtectedRoute
              exact
              path='/CSH'
              component={SituationRoomLandingPage}
            />
            <ProtectedRoute path='/CSH/:roomId' component={SituationRoom} />
            {/* {process.env.REACT_APP_ENV === "DEV" && ( */}
            <ProtectedRoute
              path='/CSH-Datafication'
              exact
              component={SituationRoomDatafication}
            />
            {/* )} */}
            {/* {process.env.REACT_APP_ENV === "DEV" && ( */}
            <ProtectedRoute
              path='/CSH-Datafication/:roomId'
              component={SituationRoomDataficationReport}
            />
            {/* )} */}
            <ProtectedRoute
              path='/CSH-insights'
              component={SituationRoomInsights}
            />
            <ProtectedRoute
              path='/CSH-ITSD'
              exact
              component={SituationRoomITSDLandingPage}
            />
            <ProtectedRoute
              path='/CSH-ITSD/user-management'
              exact
              component={SituationRoomITSDUserManagement}
            />
            <ProtectedRoute
              path='/CSH-ITSD/:id'
              component={SituationRoomITSD}
            />

            <ProtectedRoute
              exact
              path='/middleware-visualization'
              component={Middleware}
            />

            <ProtectedRoute
              exact
              path='/shift-handover'
              component={ShiftHandover}
            />
            <ProtectedRoute
              exact
              path='/shift-handover/user-management'
              component={ShiftHandoverUserManagement}
            />
            <ProtectedRoute
              exact
              path='/shift-handover/banner'
              component={ShiftHandoverBanner}
            />
          
            <ProtectedRoute
              exact
              path='/shift-handover/:id/:incidentType'
              component={ShiftHandoverDetailsPage}
            />

            <ProtectedRoute
              exact
              path='/shift-handover/live-global-operation-dashboard'
              component={ShiftHandoverLiveGlobalDashboard}
            />

            <ProtectedRoute
              exact
              path='/shift-handover/live-individual-operation-dashboard'
              component={ShiftHandoverLiveIndividualDashboard}
            />
              
            <ProtectedRoute path='*' component={NotFound} />
            {/* 
            <ProtectedRoute
              exact
              path='/osvisualization/vminsights'
              component={VMVulnerablities}
            />
            <ProtectedRoute
              path='/networkvisualization'
              component={NetworkView}
            />
            <ProtectedRoute
              exact
              path='/change-automation'
              component={ChangeAutomation}
            />
            <ProtectedRoute
              path='/apps/management/acnlc'
              component={ACNLifeCycleManagement}
            /> */}
          </Switch>
        </Suspense>
      </QueryClientProvider>
      {/* <Notifier /> */}
    </>
  );
};

export default App;